<template>
  <div v-if="permission.read_perm === 1" class="text-center">
    <v-dialog
      v-model="dialog"
      :fullscreen="wWidth < 780"
      max-width="80%"
      persistent
    >
      <!-- @click:outside="clickOutside" -->
      <template v-slot:activator="{ on, attrs }">
        <p id="archiveReturn" v-bind="attrs" v-on="on" @click="update"></p>
      </template>
      <div>
        <v-container style="background: white; border-radius: 5px">
          <v-form
            v-if="permission.create_perm == 1 || permission.update_perm == 1"
            ref="entryForm"
            @submit.prevent="submit"
            style="position: relative"
          >
            <v-card
              flat
              style="
                z-index: 2;
                position: sticky;
                top: 0;
                background: white;
                border-bottom: 1px solid black;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
              "
            >
              <div
                style="
                  display: flex;
                  jsutify-content: center;
                  align-items: center;
                  margin-left: 10px;
                "
              >
                <v-toolbar-title class="overline">
                  <p
                    :style="
                      `font-size: ${
                        wWidth > 779 ? 20 : 14
                      }px; margin: 0; font-weight: bold`
                    "
                  >
                    Formulir Pengembalian
                  </p>
                </v-toolbar-title>
              </div>
              <div class="d-flex">
                <div v-if="wWidth > 779">
                  <v-btn
                    v-if="
                      permission.create_perm == 1 || permission.update_perm == 1
                    "
                    type="submit"
                    :loading="loading"
                    rounded
                    outlined
                    small
                    elevation="1"
                    color="indigo"
                    class="indigo--text"
                    style="font-size:12px;margin-right:10px;"
                  >
                    simpan
                  </v-btn>
                </div>

                <v-btn
                  :loading="loading"
                  :rounded="wWidth > 779"
                  :outlined="wWidth > 779"
                  small
                  :elevation="wWidth > 779 ? 1 : 0"
                  color="red"
                  class="red--text"
                  style="font-size:12px;margin-right:10px;"
                  @click="close"
                  :icon="wWidth < 780"
                >
                  <span v-if="wWidth > 779"> Tutup</span>
                  <v-icon v-else color="red">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-card>

            <v-row style="padding: 15px; margin-top: 10px">
              <v-col cols="12" md="3" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Peminjam
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      :value="loanDetail.pic_name"
                      dense
                      outlined
                      style="margin: 0"
                      readonly
                    />
                  </v-col>
                </div>
              </v-col>
              <!-- <v-col cols="12" md="9" style="padding: 0"></v-col> -->

              <!-- <v-col cols="12" md="3" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Nama Peminjam
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      :value="loanDetail.employee_name"
                      dense
                      outlined
                      style="margin: 0"
                      readonly
                    />
                  </v-col>
                </div>
              </v-col> -->

              <v-col cols="12" md="3" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Dikembalikan Oleh
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      v-model="form.pic_return_name"
                      dense
                      outlined
                      style="margin: 0"
                      :rules="picReturnerRules"
                    />
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" md="6" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Tgl Pengembalian
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0"
                    ><v-text-field
                      v-model="form.return_time"
                      clearable
                      outlined
                      dense
                      type="datetime-local"
                      name="datetime"
                      step="1"
                      :rules="docReturnTimeRules"
                    />
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" md="12" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Catatan Pengembalian
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-textarea
                      v-model="form.description"
                      dense
                      auto-grow
                      outlined
                      clearable
                    />
                  </v-col>
                </div>
              </v-col>

              <v-col cols="12" md="5" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Upload Kondisi Dokumen
                      <span style="font-size: 10px; color: red; opacity: 0.5"
                        >(cooming soon)</span
                      >
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-file-input
                      disabled
                      label="- browse file -"
                      prepend-icon=""
                      outlined
                      dense
                    ></v-file-input>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="12" md="2" style="padding: 0"></v-col>
              <v-col cols="12" md="5" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="4" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Status Dokumen
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-select
                      v-model="form.doc_status"
                      :items="getDropdownDocStatus"
                      item-text="name"
                      item-value="id"
                      return-id
                      outlined
                      dense
                      style="margin: 0"
                      readonly
                    ></v-select>
                  </v-col>
                </div>
              </v-col>
            </v-row>
            <hr />
            <v-expansion-panels
              v-if="param.items !== null"
              class="mb-6"
              elevation="1"
            >
              <v-expansion-panel v-for="(item, i) in 1" :key="i">
                <v-expansion-panel-header
                  expand-icon="mdi-menu-down"
                  style="font-weight: bold"
                >
                  Detail Dokumen
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row style="padding: 15px">
                    <v-col cols="12" md="5" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="4" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Nama Dokumen
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-text-field
                            :value="form.name"
                            dense
                            outlined
                            style="margin: 0"
                            readonly
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="12" md="2" style="padding: 0"></v-col>
                    <v-col cols="12" md="5" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="4" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Tipe
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-text-field
                            v-if="form.document_type !== undefined"
                            :value="
                              form.document_type.id === 3
                                ? 'SOP'
                                : form.document_type.name
                            "
                            readonly
                            dense
                            outlined
                            style="margin: 0"
                          />
                        </v-col>
                      </div>
                    </v-col>

                    <v-col cols="12" md="5" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="4" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            No Dokumen
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-text-field
                            :value="form.doc_no"
                            readonly
                            dense
                            outlined
                            style="margin: 0"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="12" md="2" style="padding: 0"></v-col>
                    <v-col cols="12" md="5" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="4" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Kategori
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-text-field
                            :value="form.category_id"
                            readonly
                            dense
                            outlined
                            style="margin: 0"
                          />
                        </v-col>
                      </div>
                    </v-col>

                    <v-col cols="12" md="5" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="12" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Kepemilikan
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-text-field
                            :value="form.plant"
                            readonly
                            outlined
                            dense
                            style="margin: 0"
                          ></v-text-field>
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="12" md="2" style="padding: 0"></v-col>
                    <v-col cols="12" md="5" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="4" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            PIC Department
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-text-field
                            :value="form.pic_department"
                            readonly
                            outlined
                            dense
                            style="margin: 0"
                          ></v-text-field>
                        </v-col>
                      </div>
                    </v-col>

                    <v-col cols="12" md="5" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="4" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Tgl Terbit
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0"
                          ><v-text-field
                            :value="convertDate(form.doc_date)"
                            readonly
                            outlined
                            dense
                            style="margin: 0"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="12" md="2" style="padding: 0"></v-col>
                    <v-col cols="12" md="5" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="4" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Tgl Expired
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-text-field
                            :value="convertDate(form.doc_expiry_date)"
                            readonly
                            outlined
                            dense
                            style="margin: 0"
                          />
                        </v-col>
                      </div>
                    </v-col>

                    <v-col cols="12" md="5" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="4" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Penerbit
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-text-field
                            :value="form.published_by"
                            dense
                            outlined
                            style="margin: 0"
                            readonly
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="12" md="2" style="padding: 0"></v-col>
                    <v-col cols="12" md="5" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="4" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Kota
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-text-field
                            :value="form.published_city"
                            dense
                            outlined
                            style="margin: 0"
                            readonly
                          />
                        </v-col>
                      </div>
                    </v-col>

                    <v-col cols="12" md="5" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="4" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Jumlah
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-text-field
                            :value="form.doc_qty"
                            readonly
                            type="number"
                            dense
                            outlined
                            style="margin: 0"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="12" md="2" style="padding: 0"></v-col>
                    <v-col cols="12" md="5" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="4" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Satuan
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-text-field
                            :value="form.doc_qty_unit"
                            readonly
                            outlined
                            dense
                            style="margin: 0"
                          ></v-text-field>
                        </v-col>
                      </div>
                    </v-col>

                    <v-col cols="12" md="5" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="4" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Rak Index
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-text-field
                            :value="form.rack_index"
                            readonly
                            dense
                            outlined
                            style="margin: 0"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="12" md="7" style="padding: 0"></v-col>
                    <v-col cols="12" md="12" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="4" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Deskripsi
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-textarea
                            v-model="form.desc"
                            dense
                            auto-grow
                            outlined
                          />
                        </v-col>
                      </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <hr />
            <v-expansion-panels
              v-if="param.items !== null"
              class="mb-6"
              elevation="1"
            >
              <v-expansion-panel v-for="(item, i) in 1" :key="i">
                <v-expansion-panel-header
                  expand-icon="mdi-menu-down"
                  style="font-weight: bold"
                >
                  Detail Peminjaman
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-row style="padding: 15px">
                    <v-col cols="12" md="5" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="4" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            No Peminjaman
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-text-field
                            :value="loanDetail.loan_number"
                            dense
                            outlined
                            style="margin: 0"
                            readonly
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="12" md="2" style="padding: 0"></v-col>
                    <v-col cols="12" md="5" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="4" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Tgl Peminjaman
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0"
                          ><v-text-field
                            :value="
                              convertDate(loanDetail.loan_start_date) +
                                ' s/d ' +
                                convertDate(loanDetail.loan_end_date)
                            "
                            readonly
                            dense
                            style="margin: 0"
                          />
                        </v-col>
                      </div>
                    </v-col>

                    <v-col cols="12" md="5" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="4" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Peminjam
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-text-field
                            :value="loanDetail.pic_name"
                            readonly
                            dense
                            outlined
                            style="margin: 0"
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="12" md="2" style="padding: 0"></v-col>
                    <v-col cols="12" md="5" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="4" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Email
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-text-field
                            :value="loanDetail.email"
                            readonly
                            outlined
                            dense
                            style="margin: 0"
                          ></v-text-field>
                        </v-col>
                      </div>
                    </v-col>

                    <!-- <v-col cols="12" md="5" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="4" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Nama Peminjam
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-text-field
                            :value="loanDetail.employee_name"
                            readonly
                            dense
                            outlined
                            style="margin: 0"
                          />
                        </v-col>
                      </div>
                    </v-col> -->
                    <!-- <v-col cols="12" md="2" style="padding: 0"></v-col> -->

                    <v-col cols="12" md="5" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="4" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Plant
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-text-field
                            :value="loanDetail.plant"
                            readonly
                            outlined
                            dense
                            style="margin: 0"
                          ></v-text-field>
                        </v-col>
                      </div>
                    </v-col>
                    <v-col cols="12" md="2" style="padding: 0"></v-col>
                    <v-col cols="12" md="5" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="4" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Department
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-text-field
                            :value="loanDetail.department"
                            readonly
                            outlined
                            dense
                            style="margin: 0"
                          ></v-text-field>
                        </v-col>
                      </div>
                    </v-col>

                    <v-col cols="12" md="5" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="4" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Dibuatkan oleh
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-text-field
                            :value="loanDetail.created_by_name"
                            dense
                            outlined
                            style="margin: 0"
                            readonly
                          />
                        </v-col>
                      </div>
                    </v-col>
                    <!-- <v-col cols="12" md="7" style="padding: 0"></v-col> -->
                    <v-col cols="12" md="7" style="padding: 0"></v-col>

                    <v-col cols="12" md="12" style="padding: 0">
                      <div style="padding: 0 10px">
                        <v-col cols="4" style="padding: 0">
                          <p
                            style="
                              margin: 0;
                              font-size: 12px;
                              font-weight: bold;
                              color: rgba(0, 0, 0, 0.5);
                            "
                          >
                            Tujuan Penggunaan
                          </p>
                        </v-col>
                        <v-col cols="12" style="padding: 0">
                          <v-textarea
                            :value="loanDetail.loan_purpose"
                            dense
                            auto-grow
                            outlined
                          />
                        </v-col>
                      </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div v-if="wWidth < 780" style="padding: 0 0;">
              <v-btn
                v-if="permission.create_perm === 1"
                type="submit"
                rounded
                elevation="1"
                color="indigo"
                class="white--text"
                style="font-size:14px;width:100%;"
              >
                simpan
              </v-btn>
            </div>
          </v-form>
        </v-container>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import buildType from '../../../services/buildType'
export default {
  name: 'Archive-Return',
  props: ['param', 'permission', 'isAdmin'],
  data() {
    return {
      wWidth: window.innerWidth,
      eSanqua: buildType.apiURL('esanqua'),
      dialog: false,
      form: {},
      loanDetail: {},

      docReturnTimeRules: [],
      picReturnerRules: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters(['getDropdownDocStatus'])
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.wWidth = window.innerWidth
    },
    // ...mapActions(["userDropdown", "addApprover"]),
    // ...mapMutations(["setIsLoading"]),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    clear() {
      this.docReturnTimeRules = []
      this.picReturnerRules = []
      this.form = {}
      this.loanDetail = {}
      this.$emit('loadPage')
    },
    clickOutside() {
      this.clear()
    },
    close() {
      this.dialog = false
      this.clear()
    },
    update() {
      console.log(this.isAdmin)
      this.initFormDetail()
      this.initLoanedDetail()
    },
    initFormDetail() {
      setTimeout(() => {
        axios
          .get(`${this.eSanqua}archive_document/detail/${this.param.items.id}`)
          .then(res => {
            if (res.data.status_code !== '-99') {
              this.form = {
                doc_loan_id: res.data.data.loan_detail.id,
                pic_return_name: '',
                return_time: '',
                description: '',

                loaned_by_name: res.data.data.loan_detail.pic_name,
                name: res.data.data.name,
                doc_no: res.data.data.doc_no,
                plant:
                  res.data.data.plant !== null ? res.data.data.plant.name : '',
                // location_department: res.data.data.location_department_name,
                pic_department: res.data.data.pic_department_name,
                doc_date: res.data.data.doc_date,
                doc_expiry_date: res.data.data.doc_expiry_date,
                rack_index: res.data.data.rack_index,
                desc: res.data.data.description,
                doc_qty: res.data.data.doc_qty,
                doc_qty_unit:
                  res.data.data.doc_qty_unit !== null
                    ? res.data.data.doc_qty_unit.name
                    : '',
                published_by: res.data.data.published_by,
                published_city: res.data.data.published_city,
                // file: res.data.data.file,
                doc_status: res.data.data.doc_status,
                document_type: res.data.data.document_type,
                category_id:
                  res.data.data.category !== null
                    ? res.data.data.category.name
                    : ''
              }
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          })
          .catch(err => {
            this.showMsgDialog(
              'error',
              err
                ? 'Something went wrong, Please contact an admin!'
                : 'Something went wrong, Please contact an admin!',
              false
            )
          })
      }, 500)
    },
    initLoanedDetail() {
      setTimeout(() => {
        axios
          .get(
            `${this.eSanqua}archive_document/loan/detail/${this.param.items.loaned_id}`
          )
          .then(res => {
            console.log(res)
            if (res.data.status_code !== '-99') {
              this.loanDetail = {
                id: res.data.data.id,
                loan_number: res.data.data.loan_number,
                pic_name: res.data.data.pic_name,
                email: res.data.data.email,
                plant: res.data.data.plant.name,
                department: res.data.data.department.name,
                loan_start_date: res.data.data.loan_start_date,
                loan_end_date: res.data.data.loan_end_date,
                loan_purpose: res.data.data.loan_purpose,
                employee_name: res.data.data.employee.name,
                created_by_name: res.data.data.created_by_name
              }
            } else {
              // this.showMsgDialog("error", res.data.status_msg, false);
            }
          })
          .catch(err => {
            this.showMsgDialog(
              'error',
              err
                ? 'Something went wrong, Please contact an admin!'
                : 'Something went wrong, Please contact an admin!',
              false
            )
          })
      }, 500)
    },
    submit() {
      this.docReturnTimeRules = [v => !!v || 'Return Time is required']
      this.picReturnerRules = [v => !!v || 'Return by is required']

      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const newForm = {
        act: 'add',
        doc_loan_id: this.loanDetail.id,
        pic_return_name: this.form.pic_return_name,
        return_time: this.form.return_time,
        description: this.form.description
      }

      this.save(newForm)
    },
    save(form) {
      console.log('return form>>>>>', form)
      this.loading = true
      axios
        .post(`${this.eSanqua}archive_document/return/save`, form)
        .then(res => {
          console.log(res)
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.close()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    convertDate(raw) {
      if (raw !== undefined) {
        if (raw !== null) {
          if (raw === 'Invalid date') {
            return raw
          }
          const day = raw.slice(0, raw.indexOf('-'))
          let month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
          const year = raw.slice(raw.lastIndexOf('-') + 1)

          switch (month) {
            case '01':
              month = 'JAN'
              break
            case '02':
              month = 'FEB'
              break
            case '03':
              month = 'MAR'
              break
            case '04':
              month = 'APR'
              break
            case '05':
              month = 'MAY'
              break
            case '06':
              month = 'JUN'
              break
            case '07':
              month = 'JUL'
              break
            case '08':
              month = 'AGS'
              break
            case '09':
              month = 'SEP'
              break
            case '10':
              month = 'OKT'
              break
            case '11':
              month = 'NOV'
              break
            case '12':
              month = 'DES'
              break
          }
          return day + ' ' + month + ' ' + year
        } else {
          return raw
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.app-matrix-toolbar {
  padding: 10px 15px 20px 15px;
  width: 95%;
  margin-top: 30px;
}
.app-matrix-input {
  height: 76%;
  border-radius: 3px;
  padding: 30px 10px 5px;
  width: 95%;
  background: white;
  margin-top: 1px;

  .form-matrix-input-col {
    padding: 0;
    margin-top: 10px;
    .form-matrix-input-col-div {
      display: flex;
      flex-direction: row;
      .form-matrix-input-col-div-col1 {
        padding-left: 0;
        padding-right: 0;

        p {
          font-size: 14px;
          margin: 0;
          padding-left: 35px;
          text-align: left;
        }
      }
      .form-matrix-input-col-div-col2 {
        padding: 0;
      }
    }
  }
}
.doc-form-btn-1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 780px) {
  .doc-form-btn-1 {
    position: fixed;
    bottom: 0;
    left: 0;
    background: white;
    width: 100%;
  }
}
</style>
